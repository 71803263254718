<template>
  <v-card>
    <v-card-text class="text-center">
      <v-container fluid pa-0>
        <v-row no-gutters>
          <v-col cols="12">
            <p class="mt-2 text-h4 black--text">
              Point the QR code at Kiosk QR Scanner
            </p>
            <p class="mt-2 text-h3 black--text">
              {{ selectedZone.name.toUpperCase() }}
            </p>
            <p class="mt-2 text-h4 black--text">
              {{ selectedLane.lane_text_current.toUpperCase()+' - '+selectedLane.lane_id }}
            </p>
            <vue-qrcode
              v-if="qrText != ''"
              ref="qrImage"
              :value="qrText"
              :options="{ width: 300, errorCorrectionLevel: 'M' }"
            ></vue-qrcode>
          </v-col>
        </v-row>
        <v-row no-gutters class="mt-6">
          <v-col>
            <v-btn
              block
              x-large
              class="elevation-0 primary"
              @click="navigateToSummaryPage"
              >Configuration Summary</v-btn
            >
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import VueQrcode from "@chenfengyuan/vue-qrcode";
// import * as API from "@/api/api";
export default {
  name: "DisplayQr",
  components: { VueQrcode },
  data: () => ({}),
  computed: {
    ...mapGetters({
      qrText: "pinaStore/getterConfiguredLaneQRCode",
      selectedZone: "pinaStore/getterSelectedZone",
      selectedLane: "pinaStore/getterSelectedLane"
    }),
  },
  watch: {},
  mounted() {},
  methods: {
    navigateToHome(){
      this.$router.replace({path: "/home/" +
            this.selectedZone.zid +
            "/" })
    },
    navigateToSummaryPage(){
      this.$router.replace({path: "/home/" +
            this.selectedZone.zid +
            "/"+ this.selectedLane.lane_id + "/summary" })
    }
  },
};
</script>
<style>
</style>
